"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PHONE_NUMBER_FORMATS = void 0;
var _googleLibphonenumber = require("google-libphonenumber");
const phoneUtil = _googleLibphonenumber.PhoneNumberUtil.getInstance();
const PHONE_NUMBER_FORMATS = exports.PHONE_NUMBER_FORMATS = {
  E164: 'E164',
  NATIONAL: 'NATIONAL',
  INTERNATIONAL: 'INTERNATIONAL',
  AWSPhone: 'INTERNATIONAL'
};
const formatPhoneNumber = function (value) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : PHONE_NUMBER_FORMATS.E164;
  try {
    const number = phoneUtil.parseAndKeepRawInput(value, 'US');
    return phoneUtil.format(number, _googleLibphonenumber.PhoneNumberFormat[format]);
  } catch (e) {
    // console.log(`Failed to format "${value}"`, e)
  }
  return value;
};
var _default = exports.default = formatPhoneNumber;