"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.breadcrumbsSlice = exports.breadcrumbsActions = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = {
  crumbs: []
};
const breadcrumbsSlice = exports.breadcrumbsSlice = (0, _toolkit.createSlice)({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setCrumbs(state, action) {
      state.crumbs = action.payload;
    },
    clearCrumbs(state) {
      state.crumbs = [];
    }
  }
});
const breadcrumbsActions = exports.breadcrumbsActions = breadcrumbsSlice.actions;
var _default = exports.default = breadcrumbsSlice;