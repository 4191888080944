"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createRicePaperThemeOptions = void 0;
var _styles = require("@mui/material/styles");
var _lodash = _interopRequireDefault(require("lodash"));
var _settings = require("../data/settings");
var _colors = require("./colors");
var _LinkBehavior = _interopRequireDefault(require("./LinkBehavior"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FONT_SCALE_FACTOR = 0.5;
const H1_FONT_SCALE_FACTOR = 0.3;
const BG_IMAGE_PATH = _settings.ASSETS_CDN + '/images/textured-background.png?auto=compress,format';
const RICE_PAPER_THEME_NAME = 'EKS Rice Paper';
const DEFAULT_THEME_NAME = 'EKS Default';
const createThemeOptions = theme => {
  const mode = theme.palette.mode;
  const paletteBackgroundDefault = mode === 'dark' ? '#303030' : '#f2f3f4';
  const paletteBackgroundPaper = mode === 'dark' ? '#424242' : '#fff';
  const scaleFont = (_ref, scale) => {
    let {
      fontSize,
      letterSpacing,
      lineHeight,
      ...others
    } = _ref;
    const adjustedFontSize = (parseFloat(fontSize) - 1) * scale + 1 + 'rem';
    const adjustedLineHeight = (lineHeight - 1) * scale + 1;
    const adjustedLetterSpacing = parseFloat(letterSpacing) * scale + 'em';
    return {
      ...others,
      fontSize: adjustedFontSize,
      lineHeight: adjustedLineHeight,
      letterSpacing: adjustedLetterSpacing
    };
  };
  const buttonMode = mode === 'dark' ? 'light' : 'dark';
  const primaryButtonColor = _colors.COLOR_PALETTE.primary[buttonMode];
  const secondaryButtonColor = _colors.COLOR_PALETTE.secondary[buttonMode];
  return {
    name: DEFAULT_THEME_NAME,
    palette: {
      common: {
        ricePaper: _colors.RICE_PAPER_COLOR,
        squidInk: _colors.SQUID_INK_COLOR.primary
      },
      background: {
        ..._colors.COLOR_PALETTE,
        default: paletteBackgroundDefault,
        paper: paletteBackgroundPaper
      },
      mode
    },
    typography: {
      h1: scaleFont(theme.typography.h1, H1_FONT_SCALE_FACTOR),
      h2: scaleFont(theme.typography.h2, FONT_SCALE_FACTOR),
      h3: scaleFont(theme.typography.h3, FONT_SCALE_FACTOR),
      h4: scaleFont(theme.typography.h4, FONT_SCALE_FACTOR),
      h5: scaleFont(theme.typography.h5, FONT_SCALE_FACTOR),
      h6: scaleFont(theme.typography.h6, FONT_SCALE_FACTOR)
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: _LinkBehavior.default
        },
        styleOverrides: {
          root: _ref2 => {
            let {
              ownerState,
              theme: currentTheme
            } = _ref2;
            let textMode;
            if (currentTheme.name === RICE_PAPER_THEME_NAME) {
              textMode = 'dark';
            } else if (theme.palette.mode === 'dark') {
              textMode = 'light';
            } else {
              textMode = 'dark';
            }
            const color = _colors.COLOR_PALETTE.primary[textMode];
            return [{
              transition: '200ms',
              color,
              textDecorationColor: (0, _styles.alpha)(color, 0.4),
              ':hover': {
                color,
                textDecorationColor: color
              }
            }, _lodash.default.get(ownerState, 'sx')];
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: _LinkBehavior.default
        }
      },
      MuiButton: {
        styleOverrides: {
          root: _ref3 => {
            let {
              ownerState
            } = _ref3;
            return [{
              textTransform: 'none'
            }, _lodash.default.get(ownerState, 'sx')];
          },
          containedPrimary: _ref4 => {
            let {
              ownerState
            } = _ref4;
            return [{
              backgroundColor: primaryButtonColor,
              color: theme.palette.getContrastText(primaryButtonColor)
            }, _lodash.default.get(ownerState, 'sx')];
          },
          containedSecondary: _ref5 => {
            let {
              ownerState
            } = _ref5;
            return [{
              backgroundColor: secondaryButtonColor,
              color: theme.palette.getContrastText(secondaryButtonColor)
            }, _lodash.default.get(ownerState, 'sx')];
          },
          outlinedPrimary: _ref6 => {
            let {
              ownerState
            } = _ref6;
            return [{
              borderColor: primaryButtonColor,
              color: primaryButtonColor
            }, _lodash.default.get(ownerState, 'sx')];
          },
          outlinedSecondary: _ref7 => {
            let {
              ownerState
            } = _ref7;
            return [{
              borderColor: secondaryButtonColor,
              color: secondaryButtonColor
            }, _lodash.default.get(ownerState, 'sx')];
          }
        }
      },
      MuiCard: {
        defaultProps: {
          elevation: 2,
          raised: true
        }
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl'
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: _ref8 => {
            let {
              ownerState
            } = _ref8;
            return [{
              backgroundRepeat: 'repeat',
              backgroundImage: "url(".concat(BG_IMAGE_PATH, ")")
            }, _lodash.default.get(ownerState, 'sx')];
          }
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'filled'
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: _ref9 => {
            let {
              ownerState
            } = _ref9;
            return [{
              backgroundColor: paletteBackgroundPaper
            }, _lodash.default.get(ownerState, 'sx')];
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined'
        }
      },
      MuiPaper: {
        defaultProps: {
          square: true
        }
      }
    }
  };
};
const createRicePaperThemeOptions = theme => ({
  name: RICE_PAPER_THEME_NAME,
  palette: {
    background: {
      default: _colors.RICE_PAPER_COLOR,
      paper: _colors.RICE_PAPER_COLOR
    },
    text: _colors.SQUID_INK_COLOR,
    divider: _colors.SQUID_INK_DIVIDER_COLOR
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: _ref10 => {
          let {
            ownerState
          } = _ref10;
          let sx = ownerState.sx;
          if (_lodash.default.isArray(sx)) {
            sx = _lodash.default.merge({}, ...sx);
          }
          const overridesPadding = _lodash.default.has(sx, 'padding') || _lodash.default.has(sx, 'p');
          return [!overridesPadding && [{
            padding: theme.spacing(2)
          }, {
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(4)
            }
          }], _lodash.default.get(ownerState, 'sx')];
        }
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: _ref11 => {
          let {
            ownerState
          } = _ref11;
          return [{
            backgroundColor: _colors.RICE_PAPER_COLOR,
            backgroundRepeat: 'repeat',
            backgroundImage: "url(".concat(BG_IMAGE_PATH, ")")
          }, _lodash.default.get(ownerState, 'sx')];
        }
      }
    }
  }
});
exports.createRicePaperThemeOptions = createRicePaperThemeOptions;
var _default = exports.default = createThemeOptions;