import _ from 'lodash'
import { HAKUA_KAI_COLOR_PALETTE as COLOR_PALETTE } from 'react-common-eks/dist/theme/colors'
import createCommonThemeOptions from 'react-common-eks/dist/theme/create-theme-options'

const createThemeOptions = theme =>
    _.merge(createCommonThemeOptions(theme), {
        palette: COLOR_PALETTE,
    })

export default createThemeOptions
