import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import {
    Box,
    createTheme,
    CssBaseline,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material'
import { withProfiler } from '@sentry/react'
import { useEffect } from 'react'
import useColorMode from 'react-common-eks/dist/hooks/use-color-mode'
import Layout from 'react-common-eks/dist/layout/Layout'
import { brandingActions } from 'react-common-eks/dist/store/branding-slice'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { footerNavItems, relatedNavItems, siteNavItems } from './data/nav-links'
import Routes from './Routes'
import createThemeOptions from './theme/create-theme-options'

const BRANDING = 'Hakua Kai Karate-Do USA'

const App = () => {
    const dispatch = useDispatch()
    const mode = useColorMode()
    let theme = createTheme({
        palette: {
            mode,
        },
    })
    theme = createTheme(theme, createThemeOptions(theme))
    theme = responsiveFontSizes(theme)

    useEffect(() => {
        dispatch(brandingActions.setBranding(BRANDING))
    })

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <Router>
                <Layout
                    title={BRANDING}
                    relatedNavItems={relatedNavItems}
                    siteNavItems={siteNavItems}
                    footerNavItems={footerNavItems}
                >
                    <Routes />
                </Layout>
                <Box
                    id="admin-menu"
                    position="fixed"
                    sx={{ bottom: 0, left: 0, right: 0 }}
                />
            </Router>
        </ThemeProvider>
    )
}

export default withProfiler(App)
