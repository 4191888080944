"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _lodash = _interopRequireDefault(require("lodash"));
var _reduxForm = require("redux-form");
var _brandingSlice = _interopRequireDefault(require("./branding-slice"));
var _breadcrumbsSlice = _interopRequireDefault(require("./breadcrumbs-slice"));
var _contactUsFormReducer = _interopRequireDefault(require("./contact-us-form-reducer"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const createStore = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _toolkit.configureStore)(_lodash.default.merge({
    reducer: {
      // ...your other reducers here
      branding: _brandingSlice.default.reducer,
      breadcrumbs: _breadcrumbsSlice.default.reducer,
      // you have to pass formReducer under 'form' key,
      // for custom keys look up the docs for 'getFormState'
      form: _reduxForm.reducer.plugin({
        'contact-us': _contactUsFormReducer.default
        // ...applyFormReducers,
      })
    }
  }, options));
};
var _default = exports.default = createStore;