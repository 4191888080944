import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Amplify, Auth } from 'aws-amplify'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import awsExports from './aws-exports'
import reportWebVitals from './reportWebVitals'
import store from './store'

Sentry.init({
    dsn: _.get(process.env, 'REACT_APP_SENTRY_DSN'),
    integrations: [new Integrations.BrowserTracing()],
    debug: _.get(process.env, 'REACT_APP_SENTRY_DEBUG', 'false') === 'true',
})

Amplify.configure(awsExports)

Auth.configure({
    authenticationFlowType: 'USER_SRP_AUTH',
})

const url = awsExports.aws_appsync_graphqlEndpoint
const region = awsExports.aws_appsync_region
const auth = {
    type: awsExports.aws_appsync_authenticationType,
    jwtToken: async () => {
        try {
            return (await Auth.currentSession()).getIdToken().getJwtToken()
        } catch (error) {
            return ''
        }
    },
}

const httpLink = createHttpLink({ uri: url })

const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
])

const client = new ApolloClient({
    link,
    cache: new InMemoryCache({
        typePolicies: {
            Article: {
                keyFields: ['article_id'],
            },
            Association: {
                keyFields: ['association_id'],
            },
            BeltLevel: {
                keyFields: ['belt_level_id'],
            },
            Dojo: {
                keyFields: ['dojo_id'],
            },
            Event: {
                keyFields: ['event_id'],
            },
            ExperienceLevel: {
                keyFields: ['experience_level_id'],
            },
            MembershipApplication: {
                keyFields: ['membership_application_id'],
            },
            Photo: {
                keyFields: ['photo_id'],
            },
            Photoset: {
                keyFields: ['photoset_id'],
            },
            User: {
                keyFields: ['email'],
            },
        },
    }),
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Sentry.ErrorBoundary
                fallback={({ error, componentStack, resetError }) => (
                    <React.Fragment>
                        <div>You have encountered an error</div>
                        <code>{error.toString()}</code>
                        <code>{componentStack}</code>
                        <button
                            onClick={() => {
                                resetError()
                            }}
                        >
                            Click here to reset!
                        </button>
                    </React.Fragment>
                )}
                showDialog
            >
                <ApolloProvider client={client}>
                    <App />
                </ApolloProvider>
            </Sentry.ErrorBoundary>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
