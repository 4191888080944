"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _awsAmplify = require("aws-amplify");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const createMemberNavItems = (user, currentHref) => {
  if (_lodash.default.get(user, 'loading', false)) {
    return [];
  }
  let authToggle;
  if (!user || _lodash.default.isNil(user.userData)) {
    authToggle = {
      label: 'Sign In',
      onClick: () => _awsAmplify.Auth.federatedSignIn({
        customState: currentHref
      })
    };
  } else {
    authToggle = {
      label: 'Sign Out',
      onClick: () => _awsAmplify.Auth.signOut()
    };
  }
  return [authToggle];
};
var _default = exports.default = createMemberNavItems;