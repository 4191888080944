import Home from 'pages/Home'
import React from 'react'
import withSuspense from 'react-common-eks/dist/components/withSuspense'
import organizations from 'react-common-eks/dist/data/organizations'
import EKSRedirect from 'react-common-eks/dist/pages/EKSRedirect'
import HttpNotFound from 'react-common-eks/dist/pages/HttpNotFound'
import { Navigate, useRoutes } from 'react-router-dom'

const ContactUs = React.lazy(() =>
    import('react-common-eks/dist/pages/ContactUs')
)

const Dojos = React.lazy(() => import('react-common-eks/dist/pages/Dojos'))
const Event = React.lazy(() => import('react-common-eks/dist/pages/Event'))
const Events = React.lazy(() => import('react-common-eks/dist/pages/Events'))
const Membership = React.lazy(() =>
    import('react-common-eks/dist/pages/Membership')
)

const MoreOrganizations = React.lazy(() =>
    import('react-common-eks/dist/pages/MoreOrganizations')
)

const News = React.lazy(() => import('react-common-eks/dist/pages/News'))
const NewsArticle = React.lazy(() =>
    import('react-common-eks/dist/pages/NewsArticle')
)

const Photos = React.lazy(() => import('react-common-eks/dist/pages/Photos'))
const PhotoSet = React.lazy(() =>
    import('react-common-eks/dist/pages/PhotoSet')
)

const Karate = React.lazy(() => import('./pages/Karate'))

let Routes = () => {
    return useRoutes([
        { path: '/', element: <Home /> },
        { path: 'locations', element: <Dojos /> },
        {
            path: 'more-organizations',
            element: <MoreOrganizations organizations={organizations} />,
        },
        { path: 'contact-us', element: <ContactUs /> },
        {
            path: 'events',
            children: [
                { path: '', element: <Events /> },
                {
                    path: ':eventId',
                    element: <Event />,
                },
            ],
        },
        { path: 'membership', element: <Membership /> },
        {
            path: 'membership/application/*',
            element: <EKSRedirect />,
        },
        {
            path: 'membership/apply/*',
            element: <EKSRedirect />,
        },
        {
            path: 'news',
            children: [
                { path: '', element: <News /> },
                {
                    path: ':articleId',
                    element: <NewsArticle />,
                },
            ],
        },
        { path: 'photo-gallery', element: <Photos associationId={1} /> },
        { path: 'photo-gallery/:photoSetId', element: <PhotoSet /> },
        { path: 'karate', element: <Karate /> },
        { path: 'kata', element: <Navigate to="/karate#kata" /> },
        { path: 'kumite', element: <Navigate to="/karate#kumite" /> },
        { path: '*', element: <HttpNotFound /> },
    ])
}

Routes.defaultProps = {}

Routes = withSuspense(Routes)

export default Routes
