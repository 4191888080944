"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _reactRedux = require("react-redux");
var _breadcrumbsSlice = require("../store/breadcrumbs-slice");
const useBreadcrumbs = crumbs => {
  const dispatch = (0, _reactRedux.useDispatch)();
  (0, _react.useEffect)(() => {
    dispatch(_breadcrumbsSlice.breadcrumbsActions.setCrumbs(crumbs));
  }, [crumbs, dispatch]);
};
var _default = exports.default = useBreadcrumbs;