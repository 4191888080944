"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SHINTO_MUSU_RYU_NIHON_JODO_KAI_ORG_KEY = exports.RYUKYU_KOBUJUTSU_HOZON_SHINKO_KAI_ORG_KEY = exports.Organization = exports.KARATE_ORG_KEY = exports.HAKUA_KAI_ORG_KEY = exports.AVATARS = void 0;
const NAMES = {
  karate: 'Karate',
  hakua_kai: 'US Karate-Do Hakua Kai',
  ryukyu_kobujutsu_hozon_shinko_kai: 'Ryukyu Kobujutsu Hozon Shinko Kai',
  shinto_musu_ryu_nihon_jodo_kai: 'Shinto Muso Ryu Nihon Jodo Kai'
};
const AVATARS = exports.AVATARS = {
  hakua_kai: '/images/hakua-kai-usa-logo_small.png',
  ryukyu_kobujutsu_hozon_shinko_kai: '/images/ryukyu-kobujutu-logo_small.png'
};
class Organization {
  constructor(key, links) {
    this.key = "content.more.orgs.".concat(key);
    this.links = links;
    this.name = NAMES[key];
    this.avatar = AVATARS[key];
  }
}
exports.Organization = Organization;
const KARATE_ORG_KEY = exports.KARATE_ORG_KEY = 'karate';
const HAKUA_KAI_ORG_KEY = exports.HAKUA_KAI_ORG_KEY = 'hakua_kai';
const RYUKYU_KOBUJUTSU_HOZON_SHINKO_KAI_ORG_KEY = exports.RYUKYU_KOBUJUTSU_HOZON_SHINKO_KAI_ORG_KEY = 'ryukyu_kobujutsu_hozon_shinko_kai';
const SHINTO_MUSU_RYU_NIHON_JODO_KAI_ORG_KEY = exports.SHINTO_MUSU_RYU_NIHON_JODO_KAI_ORG_KEY = 'shinto_musu_ryu_nihon_jodo_kai';
const getOrganization = associationId => {
  let key;
  if (associationId === 1) {
    key = HAKUA_KAI_ORG_KEY;
  } else if (associationId === 2) {
    key = RYUKYU_KOBUJUTSU_HOZON_SHINKO_KAI_ORG_KEY;
  } else {
    throw new Error("Unknown association ID \"".concat(associationId, "\""));
  }
  return new Organization(key);
};
var _default = exports.default = getOrganization;