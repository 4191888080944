import { useQuery } from '@apollo/client'
import { Box, Link, Paper, Stack, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import ErrorHandler from 'react-common-eks/dist/components/ErrorHandler'
import ImgIX from 'react-common-eks/dist/components/ImgIX'
import Paragraph from 'react-common-eks/dist/components/Paragraph'
import RicePaper from 'react-common-eks/dist/components/RicePaper'
import formatPhoneNumber, {
    PHONE_NUMBER_FORMATS,
} from 'react-common-eks/dist/data/formaters/format-phone-number'
import { SHIHAN_YEARS_TEACHING } from 'react-common-eks/dist/data/settings'
import useBreadcrumbs from 'react-common-eks/dist/hooks/use-breadcrumbs'
import { Helmet } from 'react-helmet'

import { GET_SHIHAN_CONTACT_INFO } from './queries'

const IWABUCHI_PHOTO = '/images/Iwabuchi-with-friends.png'

const SHIHAN_EMAIL_KEY = 'eks.shihan.email'
const SHIHAN_PHONE_KEY = 'eks.shihan.phone'

const Home = () => {
    useBreadcrumbs([])
    const [email, setEmail] = useState(localStorage.getItem(SHIHAN_EMAIL_KEY))
    const [phone, setPhone] = useState(localStorage.getItem(SHIHAN_PHONE_KEY))
    const { data, loading, error } = useQuery(GET_SHIHAN_CONTACT_INFO)

    useEffect(() => {
        if (data) {
            const foundEmail = _.get(data, 'shihan.email')
            if (!_.isNil(foundEmail)) {
                setEmail(foundEmail)
                localStorage.setItem(SHIHAN_EMAIL_KEY, foundEmail)
            }
            const foundPhone = _.get(data, 'shihan.phone')
            if (!_.isNil(foundPhone)) {
                setPhone(foundPhone)
                localStorage.setItem(SHIHAN_PHONE_KEY, foundPhone)
            }
        }
    }, [data])

    if (error) return <ErrorHandler error={error} />

    const imgParams = {
        fit: 'crop',
        'fp-x': 0.2,
        'fp-y': 0.5,
        ar: '2:1',
    }
    return (
        <Paragraph
            component="article"
            disableGutters
            id="organization-hakua-kai"
            itemScope
            itemType="https://schema.org/Organization"
            sx={{ mt: { sm: 4 } }}
        >
            <Helmet>
                <title>Welcome to Hakua Kai Karate-Do USA</title>
            </Helmet>
            <Stack spacing={2}>
                <Paper sx={{ p: 4 }}>
                    <Box
                        sx={{ float: 'right', marginLeft: 1, marginBotton: 1 }}
                    >
                        <ImgIX
                            src="/images/hakua-kai-usa-logo.png"
                            alt="Hakua Kai Karate-Do USA logo"
                        />
                    </Box>
                    <Box>
                        <Typography paragraph>
                            The Hakua Kai Karate-Do USA is affiliated with Japan
                            Karate-Do Hakua Kai, which was founded by the late
                            Grand Master Instructor Katsushi Iwabuchi.
                        </Typography>
                        <Typography paragraph>
                            Shihan Edwards is the Chief Master Instructor of US
                            Karate-Do Hakua Kai. Also, he is the President and
                            Founder of Edwards Karate School. The school has
                            been teaching traditional Japanese Hakua Kai
                            Karate-Do to adults and children in the San
                            Francisco Peninsula area and throughout the United
                            States for the last {SHIHAN_YEARS_TEACHING} years.
                        </Typography>
                        <Typography paragraph>
                            For his contribution to the traditional Japanese
                            martial arts, Shihan Edwards was inducted into the{' '}
                            <Link href="http://www.mastershalloffame.org/?q=content/mhof-inductee-list-2010">
                                Martial Arts Masters Hall of Fame in 2010
                            </Link>{' '}
                            , the{' '}
                            <Link href="http://www.nichibeikai.org/page2/index.html">
                                Hokka Nichibeikai Bunka Hall of Fame in 2016
                            </Link>
                            , and the Bay Area Cities Karate and Kobudo Alliance
                            Masters Hall of Fame in 2017. The Martial Arts
                            Masters Hall of Fame recognizes the martial arts
                            community leaders. The Bunka Hall of Fame is to
                            honor those who have excelled and contributed to the
                            culture of Japan and bettered relationships in our
                            community by enlightening all to the culture of
                            Japan as practiced here in America.
                        </Typography>
                    </Box>
                </Paper>
                <RicePaper>
                    <Typography variant="h2" gutterBottom>
                        Origin of Hakua Kai
                    </Typography>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <Stack width={1.0}>
                            <ImgIX
                                src={IWABUCHI_PHOTO}
                                params={imgParams}
                                width="100%"
                                aria-labelledby="name-person-katsushi-iwabuchi"
                            />
                            <Typography
                                variant="caption"
                                id="name-person-katsushi-iwabuchi"
                            >
                                <b>Back row</b>: (left to right) Takano Sensei,
                                Edwards Sensei, Takakuwa Sensei, Tanabe Sensei
                                and Otani Sensei
                                <br />
                                <b>Front Row</b>: (left to right) Iwabuchi
                                Shihan, Demura Shihan, Imai Sensei, and Fakuda
                                Sensei
                            </Typography>
                        </Stack>
                        <Box maxWidth={{ xs: 1, md: 0.5, lg: 0.67 }}>
                            <Typography paragraph>
                                Shihan Katsushi Iwabuchi founded Hakua Kai in
                                Japan. The meaning of Hakua is "white wall,"
                                "free like a dove, " or "pure." As per Shihan
                                Bernard Edwards, Hakua Kai is a unique
                                discipline that utilizes both the hands and feet
                                while emphasizing body position and switching
                                techniques.
                            </Typography>
                            <Typography paragraph>
                                Iwabuchi Shihan was widely known and respected
                                throughout Japan's martial arts community and
                                worldwide. He led an extremely active life, and
                                one of his greatest pleasures was visiting and
                                teaching in California. A senior student and
                                head of the US Hakua Kai Karate-Do Association,
                                Sensei Bernard Edwards often invited his
                                teacher, Iwabuchi Shihan, to share his knowledge
                                with his students.
                            </Typography>
                        </Box>
                    </Stack>
                </RicePaper>
                <Paper sx={{ padding: 4 }}>
                    <Typography variant="h2" gutterBottom>
                        Classes
                    </Typography>
                    <Typography paragraph>
                        Regularly scheduled class can be found at the parks &
                        recreation centers at the following locations:
                    </Typography>

                    <ul>
                        <li>
                            <Link href="/contact-us">
                                San Mateo Martial Arts Academy
                            </Link>
                        </li>
                        <li>
                            <Link href="http://www.cityofsanmateo.org">
                                City of San Mateo Parks &amp; Recreation at the
                                Senior's Center
                            </Link>
                        </li>
                        <li>
                            <Link href="http://www.fostercity.org">
                                Foster City Parks and Recreation Department
                            </Link>
                        </li>
                        <li>
                            <Link href="http://www.burlingame.org/rec">
                                Burlingame Parks &amp; Recreation Department
                            </Link>
                        </li>
                        <li>
                            <Link href="https://www.dalycity.org/327/Recreation">
                                City of Daly City Parks and Recreation
                            </Link>
                        </li>
                    </ul>

                    <Typography paragraph>
                        A two-hour session will be held once a month. Please{' '}
                        <Link href="/contact-us">contact us</Link> for more
                        information.
                    </Typography>

                    <Typography paragraph>
                        Private classes are available by appointment for
                        personal safety, semi-private, and special weapons
                        classes.
                    </Typography>

                    <Typography aria-busy={loading}>
                        Please contact Shihan Edwards for specific details by{' '}
                        {email && <Link href={`mailto:${email}`}>email</Link>}
                        {!email && 'email'} or by phone
                        {phone && (
                            <>
                                {' at '}
                                {formatPhoneNumber(
                                    phone,
                                    PHONE_NUMBER_FORMATS.NATIONAL
                                )}
                            </>
                        )}
                        .
                    </Typography>
                </Paper>
            </Stack>
        </Paragraph>
    )
}

export default Home
