"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reduxForm = require("redux-form");
const contactUsFormReducer = (state, action) => {
  if (action.type === _reduxForm.actionTypes.SET_SUBMIT_SUCCEEDED) {
    return undefined;
  }
  return {
    ...state
  };
};
var _default = exports.default = contactUsFormReducer;