import * as Sentry from '@sentry/react'
import createStore from 'react-common-eks/dist/store' // See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/

// See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
})

const store = createStore({
    reducer: {
        // ...your other reducers here
    },
    enhancers: [sentryReduxEnhancer],
})

export default store
