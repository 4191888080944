"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shintoMusuRyuNihonJodoKaiOrg = exports.ryukyuKobujutsuHozonShinkoKaiOrg = exports.karateOrg = exports.hakuaKaiOrg = exports.default = void 0;
var _getOrganization = require("./getOrganization");
const KARATE = [{
  name: 'World Karate Federation',
  href: 'http://www.wkf.net'
}, {
  name: 'U.S.A. Karate, Team U.S.A.',
  href: 'https://www.teamusa.org/USA-Karate'
}, {
  name: 'Amateur Athletic Union (AAU)',
  href: 'http://www.aaukarate.org'
}];
const HAKUA_KAI = [{
  name: 'Japan Karate Do Hakuakai',
  href: 'http://www.hakuakaikarate.org/home.html'
}, {
  name: 'Nihon Karate Do Hakua-Kai Matsubushi Dojo',
  href: 'http://karatedo.hakuakai-matsubushidojo.com/home.html'
}, {
  name: 'Sri Lanka Karate-Do Hakua-Kai Association',
  href: 'http://www.teba.lk'
}, {
  name: 'Japan Karate Do Hakuakai-India',
  href: 'https://jkhindia.com'
},
// {
//     name: 'Hakuakai Karate Do – Iran',
//     href: 'http://iranhakuakai.com'
// },
{
  name: 'Japan Karate-Do Hakuakai Australia',
  href: 'https://karategoldcoast.com.au'
}];
const RYUKYU_KOBUJUTSU_HOZON_SHINKO_KAI = [{
  name: 'Ryukyu Kobujutsu HQ, General Headquarters, Tokyo, Japan',
  href: 'http://www.ryukyukobujutsuhozonshinkokai.org'
}, {
  name: 'Ryukyu Kobujutsu Association Great Brittan',
  href: 'http://www.rkagb.com'
}, {
  name: 'Higashi School of Karate and Kobujutsu, Canada',
  href: 'http://www.sportspourtous.ca/ACRKK/index.html'
}, {
  name: 'Ryukyu Kobujutsu Association, Finland',
  href: 'https://kobujutsu.fi'
}, {
  name: 'Ryukyu Kobujutsu Association, Republic of South Africa',
  href: 'http://www.yuishinkai.org'
}, {
  name: 'Ryukyu Kobujutsu Hozon Shinko Kai, Holland',
  href: 'http://www.ryukyukobujutsu.nl'
}, {
  name: 'Ryukyu Kobujutsu Hozon Shinko Kai, Sweden',
  href: 'http://www.rkhsk.se'
}, {
  name: 'Yuishinkai Kobujutsu, USA',
  href: 'https://sites.google.com/site/yuishinkaikobujutsuusa/home'
}, {
  name: 'Yuishinkai Kobujutsu Hellas, Greece',
  href: 'http://www.yuishinkai.gr'
}];
const SHINTO_MUSU_RYU_NIHON_JODO_KAI = [{
  name: 'Shindo Muso Ryu Jodo, The Capital Area Jodokai, Washington D. C. Area',
  href: 'http://www.shindomusoryu.org'
}, {
  name: 'Godaiko Jodokai, Milwaukee, WI',
  href: 'http://www.godaikojodokai.com'
}, {
  name: 'The Texas Jodokai of "The Dojo" in Denton, Texas',
  href: 'https://dentondojo.com'
}];
const karateOrg = exports.karateOrg = new _getOrganization.Organization(_getOrganization.KARATE_ORG_KEY, KARATE);
const hakuaKaiOrg = exports.hakuaKaiOrg = new _getOrganization.Organization(_getOrganization.HAKUA_KAI_ORG_KEY, HAKUA_KAI);
const ryukyuKobujutsuHozonShinkoKaiOrg = exports.ryukyuKobujutsuHozonShinkoKaiOrg = new _getOrganization.Organization(_getOrganization.RYUKYU_KOBUJUTSU_HOZON_SHINKO_KAI_ORG_KEY, RYUKYU_KOBUJUTSU_HOZON_SHINKO_KAI);
const shintoMusuRyuNihonJodoKaiOrg = exports.shintoMusuRyuNihonJodoKaiOrg = new _getOrganization.Organization(_getOrganization.SHINTO_MUSU_RYU_NIHON_JODO_KAI_ORG_KEY, SHINTO_MUSU_RYU_NIHON_JODO_KAI);
const organizations = [karateOrg, hakuaKaiOrg, ryukyuKobujutsuHozonShinkoKaiOrg, shintoMusuRyuNihonJodoKaiOrg];
var _default = exports.default = organizations;