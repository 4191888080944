"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _material = require("@mui/material");
const useColorMode = () => {
  const prefersDarkMode = (0, _material.useMediaQuery)('(prefers-color-scheme: dark)');
  return prefersDarkMode ? 'dark' : 'light';
};
var _default = exports.default = useColorMode;