import { gql } from '@apollo/client'

export const GET_SHIHAN_CONTACT_INFO = gql`
    query Shihan {
        shihan {
            email
            phone
        }
    }
`
