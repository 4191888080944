"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SHIHAN_YEARS_TEACHING = exports.ASSETS_CDN = void 0;
const ASSETS_CDN = exports.ASSETS_CDN = 'https://eks-static-assets.imgix.net';
const SHIHAN_YEARS_TEACHING = exports.SHIHAN_YEARS_TEACHING = new Date().getFullYear() - 1970;
const settings = {
  ASSETS_CDN,
  SHIHAN_YEARS_TEACHING
};
var _default = exports.default = settings;