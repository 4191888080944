import { Email as EmailIcon, Home as HomeIcon } from '@mui/icons-material'
import React from 'react'

export const siteNavItems = [
    { href: '/membership', label: 'Membership' },
    { href: '/events', label: 'Events' },
    { href: '/locations', label: 'Member Dojos' },
    { href: '/news', label: 'News' },
    { href: '/photo-gallery', label: 'Photos' },
    { href: '/karate', label: 'Karate' },
    { href: '/karate#kata', label: 'Kata' },
    { href: '/karate#kumite', label: 'Kumite' },
    { href: '/more-organizations', label: 'Links' },
]
export const relatedNavItems = [
    {
        href: 'https://www.edwardskarateschool.com',
        label: 'Edwards Karate School',
        target: '_blank',
    },
    {
        href: 'https://www.rkhskusa.com',
        label: 'Ryukyu Kobujutsu Hozon Shinko Kai',
        target: '_blank',
    },
    {
        href: 'https://www.edwardskarateschool.com/shinto-musu-ryu-nihon-jodo-kai',
        label: 'Shinto Muso Ryu Nihon Jodo Kai',
    },
]
export const footerNavItems = [
    { label: 'Home', icon: <HomeIcon />, href: '/' },
    { label: 'Contact Us', icon: <EmailIcon />, href: '/contact-us' },
]
