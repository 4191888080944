"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
const ScrollToTop = () => {
  const location = (0, _reactRouterDom.useLocation)();
  (0, _react.useEffect)(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location]);
  return null;
};
var _default = exports.default = ScrollToTop;