"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SQUID_INK_DIVIDER_COLOR = exports.SQUID_INK_COLOR = exports.RYUKYU_COLOR_PALETTE = exports.RICE_PAPER_COLOR = exports.HAKUA_KAI_COLOR_PALETTE = exports.EKS_COLOR_PALETTE = exports.COLOR_PALETTE = void 0;
const RICE_PAPER_COLOR = exports.RICE_PAPER_COLOR = '#e9e5da';
const SQUID_INK_COLOR = exports.SQUID_INK_COLOR = {
  disabled: 'rgba(77, 78, 92, 0.5)',
  icon: 'rgba(77, 78, 92, 0.5)',
  primary: 'rgba(77, 78, 92, 1)',
  secondary: 'rgba(77, 78, 92, 0.7)'
};
const SQUID_INK_DIVIDER_COLOR = exports.SQUID_INK_DIVIDER_COLOR = 'rgba(81, 95, 95, 0.12)';
const COLOR_PALETTE = exports.COLOR_PALETTE = {
  primary: {
    contrastText: '#fff',
    main: '#126fcb',
    light: '#72B3F3',
    dark: '#004599'
  },
  secondary: {
    contrastText: '#fff',
    main: '#b71c1c',
    light: '#EF9999',
    dark: '#b71c1c'
  }
};
const HAKUA_KAI_COLOR_PALETTE = exports.HAKUA_KAI_COLOR_PALETTE = {
  ...COLOR_PALETTE,
  site: {
    main: '#004599',
    contrastText: '#fff'
  }
};
const RYUKYU_COLOR_PALETTE = exports.RYUKYU_COLOR_PALETTE = {
  ...COLOR_PALETTE,
  site: {
    main: '#b71c1c',
    contrastText: '#fff'
  }
};
const EKS_COLOR_PALETTE = exports.EKS_COLOR_PALETTE = {
  ...COLOR_PALETTE,
  site: {
    main: '#126fcb',
    contrastText: '#fff'
  }
};