"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.brandingSlice = exports.brandingActions = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = {
  brand: 'Edwards Karate School'
};
const brandingSlice = exports.brandingSlice = (0, _toolkit.createSlice)({
  name: 'branding',
  initialState,
  reducers: {
    setBranding(state, action) {
      state.brand = action.payload;
    }
  }
});
const brandingActions = exports.brandingActions = brandingSlice.actions;
var _default = exports.default = brandingSlice;